import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Compressor from "compressorjs";

const CameraContext = createContext();
export const CameraProvider = ({ children }) => {
  //ref
  const webcamRef = useRef();
  //devices to enumrate
  const [devices, setDevices] = useState([]);
  const [focusRange, setFocusRange] = useState(300);
  const [selectedDevice, setSelectedDevice] = useState({});

  //capture mode
  const [captureMode, setCaptureMode] = useState("front");
  const [boundOut, setBoundOut] = useState(false);

  //faces
  const [frontFace, setFrontFace] = useState();
  const [leftFace, setLeftFace] = useState();
  const [rightFace, setRightFace] = useState();

  //matched modal
  const [showMatchFoundModal, setShowMatchFoundModal] = useState(false);
  const [matchedLabor, setMatchedLabor] = useState();

  const [faceVerificationFilterData, setFaceVerificationFilterData] = useState({
    region: null,
    gender: null,
  });

  function base64ToFile(dataurl, filename) {
    console.log(dataurl);
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot({
      width: 2448,
      height: 1836,
    });

    console.log("file", imageSrc);

    new Compressor(base64ToFile(imageSrc), {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      convertSize: 500000,
      strict: false,
      success: async (compressedResult) => {
        let picture = await blobToBase64(compressedResult);
        console.log(picture);
        if (captureMode === "left") {
          setLeftFace(picture);
        } else if (captureMode === "right") {
          setRightFace(picture);
        } else if (captureMode === "front") {
          setFrontFace(picture);
        }
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, captureMode]);

  const handleDevices = useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <CameraContext.Provider
      value={{
        capture,
        setCaptureMode,
        setLeftFace,
        setRightFace,
        setFrontFace,
        setSelectedDevice,
        setFaceVerificationFilterData,
        boundOut,
        setBoundOut,
        focusRange,
        setFocusRange,
        showMatchFoundModal,
        setShowMatchFoundModal,
        matchedLabor,
        setMatchedLabor,
        faceVerificationFilterData,
        selectedDevice,
        captureMode,
        leftFace,
        frontFace,
        rightFace,
        devices,
        webcamRef,
      }}
    >
      {children}
    </CameraContext.Provider>
  );
};

export default CameraContext;
